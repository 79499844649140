export enum Routes {
  Index = 'index',
  AdminPermissionDenied = 'admin-permission-denied',
  TerminalPermissionDenied = 'terminal-permission-denied',

  // auth
  AuthSignin = 'signin',

  // public
  PublicExportAnswersByQuestion = 'public-export-answers-by-question',
  PublicExportAnswersByUser = 'public-export-answers-by-user',

  // admin
  AdminDashboard = 'admin-dashboard',
  AdminTerminals = 'admin-terminals',
  AdminClients = 'admin-clients',
  AdminForms = 'admin-forms',
  AdminAnswersByQuestion = 'admin-answers-by-question',
  AdminAnswersByUser = 'admin-answers-by-user',
  AdminForm = 'admin-form',
  AdminTranslations = 'admin-translations',
  AdminUsers = 'admin-users',
  AdminApplicants = 'admin-applicants',
  AdminEmployees = 'admin-employeed',
  AdminUser = 'admin-user',
  AdminPapers = 'admin-papers',
  AdminPaper = 'admin-paper',
  AdminVacationRequests = 'admin-vacation-requests',

  // client
  // ClientDashboard = 'client-dashboard',
  // ClientPapers = 'client-papers',
  // ClientUsers = 'client-users',
  // ClientUser = 'client-user',
  // ClientAssignPaper = 'client-assign-paper',

  // terminal
  TerminalRegister = 'terminal-register',
  TerminalRegisterNotApproved = 'terminal-register-not-approved',
  TerminalSignup = 'terminal-signup',
  TerminalSignin = 'terminal-signin',
  TerminalIntro = 'terminal-intro',
  TerminalSelectLanguage = 'terminal-select-language',
  TerminalScanPassport = 'terminal-scan-passport',
  TerminalCheckPassport = 'terminal-check-passport',
  TerminalCheckIdentity = 'terminal-check-identity',
  TerminalDashboard = 'terminal-dashboard',
  TerminalLastEmployments = 'terminal-last-employments',
  TerminalForm = 'terminal-form',
  TerminalFormStep = 'terminal-form-step',
}
