import cookies from 'js-cookie';
import { defineStore } from 'pinia';
import qs from 'qs';
import { UserDto } from '~/types/api/user/user.dto';
import { SigninDto } from '~/types/api/auth/signin.dto';
import { SignupDto } from '~/types/api/auth/signup.dto';

const TOKEN_KEY = 'session-token';

export const useAuthStore = defineStore('auth', () => {
  const isAuthenticated = ref<boolean>(false);
  const token = ref<string | null>(null);
  const user = ref<UserDto | null>(null);

  const signup = async (data: SignupDto, locale?: string) => {
    const q = qs.stringify({ lang: locale }, { encodeValuesOnly: true });
    const res = await useApi({ locale }).post(`/auth/signup?${q}`, data);
    setToken(res.data.token);
    user.value = res.data.user;
    isAuthenticated.value = true;
  };

  const signin = async (data: SigninDto, locale?: string) => {
    const res = await useApi({ locale }).post('/auth/signin', data);
    setToken(res.data.token);
    user.value = res.data.user;
    isAuthenticated.value = true;
  };

  const signout = () => {
    isAuthenticated.value = false;
    user.value = null;
    setToken(null);
  };

  const getCurrentUser = async (): Promise<UserDto | null> => {
    try {
      const res = await useApi().get<UserDto>('/auth');
      setToken(token.value);
      return res.data;
    } catch (err) {
      return null;
    }
  };

  const getIsAuthenticated = async () => {
    user.value = await getCurrentUser();
    if (user.value) {
      isAuthenticated.value = true;
    } else {
      isAuthenticated.value = false;
      setToken(null);
    }
    return isAuthenticated.value;
  };

  const getToken = () => {
    token.value = cookies.get(TOKEN_KEY) ?? null;
    return token.value;
  };
  const setToken = (value: string | null) => {
    token.value = value;
    if (value === null) {
      cookies.remove(TOKEN_KEY);
    } else {
      cookies.set(TOKEN_KEY, value, {
        expires: Number(import.meta.env.AUTH_TOKEN_MAX_AGE) * 24 * 60 * 60,
        secure: true,
        sameSite: 'strict',
      });
    }
  };

  return {
    signup,
    signin,
    signout,
    getCurrentUser,
    getIsAuthenticated,
    getToken,
    user,
    isAuthenticated,
  };
});
