/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Composables
import { createVuetify } from 'vuetify';

// components
// import * as components from 'vuetify/components';
// import * as directives from 'vuetify/directives';
// import { VConfirmEdit } from 'vuetify/labs/VConfirmEdit';
import { VNumberInput } from 'vuetify/labs/VNumberInput';

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  components: {
    // VConfirmEdit,
    VNumberInput,
  },
  theme: {
    defaultTheme: 'dark',

    themes: {
      light: {
        colors: {
          primary: '#0099a4',
        },
      },
      dark: {
        dark: true,
        colors: {
          primary: '#0099a4',
          // background: '#212121',
          // surface: '#2c2c2c',
        },
      },
    },
  },

  defaults: {
    VRadioGroup: {
      color: 'primary',
      density: 'comfortable',
      hideDetails: 'auto',
    },
    VCheckbox: {
      color: 'primary',
      density: 'comfortable',
      hideDetails: 'auto',
    },
    VTextField: {
      color: 'primary',
      density: 'compact',
      hideDetails: 'auto',
      variant: 'outlined',
    },
    VTextarea: {
      color: 'primary',
      density: 'compact',
      hideDetails: 'auto',
      variant: 'outlined',
    },
    VDatePicker: {
      color: 'primary',
      style: {
        borderRadius: '10px',
      },
    },
    VSelect: {
      color: 'primary',
      density: 'compact',
      hideDetails: 'auto',
      variant: 'outlined',
    },
    VNumberInput: {
      color: 'primary',
      density: 'compact',
      hideDetails: 'auto',
      variant: 'outlined',
      inset: true,
    },
    VLabel: {
      style: {
        opacity: 1,
      },
    },
    VTooltip: {
      openDelay: 500,
      activator: 'parent',
    },
    VDialog: {
      maxWidth: 640,
    },
  },
});
